<template>
  <div class="container-fluid header-bg">
    <div class="container">
      <header>
        <div class="row">
          <div class="col-12 col-md-6 navbar-brand">
            <router-link to="/" :style="logoStyle"></router-link>
          </div>
          <div class="col-12 col-md-6 access-panel" v-if="isLoggedIn">
            {{ username }} {{ country }}
            <router-link
              to="/customizacion.html"
              class="customizacion"
              v-if="isLoggedIn"
              title="Creación de lotes"
            ></router-link>
            <router-link
              to="/listado.html"
              class="listado"
              v-if="isLoggedIn"
              title="Administración de Lotes"
            ></router-link>

            <router-link
              to="/user-list.html"
              class="perfil"
              v-if="isAdmin"
              title="Usuarios"
            ></router-link>
            <a class="desconectarse" @click="logOut" title="Salir"></a>
          </div>
        </div>
      </header>
    </div>
  </div>
  <!--
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/home" exact exact-active-class="active">Home</b-nav-item>
        <b-nav-item to="/about" exact exact-active-class="active">About</b-nav-item>
        <b-nav-item to="/process" exact exact-active-class="active">Process</b-nav-item>
        <b-nav-item to="/config" exact exact-active-class="active">Config</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button variant="primary">hola</b-button>
      </b-navbar-nav>
    </b-collapse>

    -->
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login.html");
      });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    username() {
      return this.$store.state.auth.user.username;
    },
    country() {
      let flag = "";
      switch (this.$store.state.auth.user.country) {
        case "Guatemala":
          flag = "🇬🇹";
          break;
        case "Costa Rica":
          flag = "🇨🇷";
          break;
        case "El Salvador":
          flag = "🇸🇻";
          break;
        case "Honduras":
          flag = "🇭🇳";
          break;
        case "Panama":
          flag = "🇵🇦";
          break;
        case "Ecuador":
          flag = "🇪🇨";
          break;
        case "world":
          flag = "🌎";
          break;
      }

      return flag;
    },
    isAdmin() {
      return this.username === "admin";
    },
    title() {
      return this.$store.state.app.title;
    },
    logo() {
      return this.$store.state.app.logo;
    },
    logoStyle() {
      let logo = "";
      let country = "";

      if (this.$store.state.auth.user?.country) {
        country = this.$store.state.auth.user.country;
      } else {
        if (this.$route.path.includes("/form/")) {
          if (this.$route.path.includes("/form/costarica")) {
            country = "Costa Rica";
          }
          if (this.$route.path.includes("/form/guatemala")) {
            country = "Guatemala";
          }
          if (this.$route.path.includes("/form/elsalvador")) {
            country = "El Salvador";
          }
          if (this.$route.path.includes("/form/honduras")) {
            country = "Honduras";
          }
          if (this.$route.path.includes("/form/panama")) {
            country = "Panamá";
          }
          if (this.$route.path.includes("/form/ecuador")) {
            country = "Ecuador";
          }
        }
      }

      switch (country) {
        case "Guatemala":
          logo = "/images/pan-american-life-insurance-guatemala-color.png";
          break;
        case "Costa Rica":
          logo = "/images/pan-american-life-insurance-costa-rica-white.png";
          break;
        case "El Salvador":
          logo = "/images/pan-american-life-insurance-elsalvador-color.png";
          break;
        case "Honduras":
          logo = "/images/pan-american-life-insurance-honduras-color.png";
          break;
        case "Panamá":
          logo = "/images/pan-american-life-insurance-panama-color.png";
          break;
        case "Ecuador":
          logo = "/images/pan-american-life-insurance-ecuador-color.png";
          break;
        case "world":
          logo = "/images/pan-american-life-insurance.png";
          break;
        default:
          logo = "";
          break;
      }

      return {
        background: `url('${logo}') no-repeat center`,
      };
    },
    index() {
      return this.$store.state.wizard.form.profesion;
    },
  },
};
</script>
